import React, { useState, useRef } from "react";
import {
  Box,
  LinearProgress,
  Paper,
  Stack,
  Tooltip,
  Typography,
  paperClasses,
  tooltipClasses,
} from "@mui/material";
import { linearProgressClasses } from "@mui/material/LinearProgress";
import { filter, find, get, map, round, first, includes } from "lodash";
import Chip from "../Chip";
import RichText from "../rich-text";

const TierItem = ({
  amount: { amountValue, amountPart, showAmount },
  tier,
  currencySymbol,
  scaleLabel,
}) => {
  const popperRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const tierItemComponent = (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Tooltip
        title={tier.name}
        arrow
      >
        <div
          style={{
            // fontSize: "3vw",
            position: "absolute",
            transform: "translate(-50%, 0)",
            left: "50%",
            zIndex: 100,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "25px",
            // cut text
            overflow: "hidden",
            whiteSpace: "wrap",
            textOverflow: "ellipsis",
            width: "90%"
          }}
        >
          {tier.name}
        </div>
      </Tooltip>
      <LinearProgress
        variant="determinate"
        value={amountPart}
        ref={setAnchorEl}
        sx={{
          width: "100%",
          height: 25,
          borderRadius: 5,
          [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: tier.badgeBackgroundColor + 80,
          },
          [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: tier.badgeBackgroundColor,
          },
        }}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "flex-start",
        }}
      >
        <span>{`${currencySymbol}${scaleLabel}`}</span>
      </Box>
    </Box>
  );

  if (!showAmount) return tierItemComponent;

  return (
    <Tooltip
      arrow
      open
      placement="top"
      title={`${currencySymbol} ${amountValue}`}
      aria-label={`${currencySymbol} ${amountValue}`}
      PopperProps={{
        popperRef,
        anchorEl: {
          getBoundingClientRect: () => {
            return new DOMRect(
              (anchorEl &&
                anchorEl.getBoundingClientRect().x +
                  anchorEl.getBoundingClientRect().width *
                    (amountPart / 100)) ||
                0,
              (anchorEl && anchorEl.getBoundingClientRect().y) || 0,
              0,
              0
            );
          },
        },
      }}
      componentsProps={{
        tooltip: {
          sx: {
            color: tier.badgeTextColor,
            backgroundColor: tier.badgeBackgroundColor,
            fontSize: "1em",
            [`& .${tooltipClasses.arrow}`]: {
              "&::before": {
                backgroundColor: tier.badgeBackgroundColor,
              },
            },
          },
        },
      }}
    >
      {tierItemComponent}
    </Tooltip>
  );
};

const TiersList = ({
  currentTierId,
  groups,
  tiers,
  appStyles,
  currencySymbol,
  T,
  className,
  variant = "rewardsPage",
  user
}) => {
  const currentTier = find(groups, { id: currentTierId }) || {};
  const visibleTiers =  filter(tiers, (policy) => get(find(groups, {id: policy.groupId}), "shouldDisplayToUser"));

  const totalAmountTier = first(filter(tiers, tier => get(tier, "remainder") != 0));
  let totalAmount = round(get(totalAmountTier, "conditionalAmount") - get(totalAmountTier, "remainder"), 2);
  if (!totalAmountTier){
    console.log("not totalAmountTier", user, get(user, "loyaltyProfile.data.moneySpent"));
    totalAmount = round(get(user, "loyaltyProfile.data.moneySpent", ""), 2);
  }
  const amounts = visibleTiers.map(({ conditionalAmount, remainder }, i, tiersArr) => {
    const amount = remainder == 0 ? conditionalAmount : round(conditionalAmount - remainder, 2);
    const isLastTier = i === tiersArr.length - 1;

    const { conditionalAmount: nextConditionalAmount } = tiersArr[isLastTier ? i : i + 1] || {};

    const isInsideThisTierRange = remainder == 0 && totalAmount <= nextConditionalAmount && totalAmount > conditionalAmount;

    // if below conditionalAmount shouldn't show tooltip
    if (!nextConditionalAmount || (isInsideThisTierRange && nextConditionalAmount-conditionalAmount === 0) || !totalAmountTier){
      return {
        amountValue: totalAmount,
        amountPart: 100,
        showAmount: true,
      };
    }
    
    return {
      amountValue: totalAmount,
      amountPart: isInsideThisTierRange ? ((totalAmount - conditionalAmount)/ (nextConditionalAmount - conditionalAmount)) * 100 : 0,
      showAmount: isInsideThisTierRange,
    };
  });

  const variantStylesMap = {
    homePage: {
      [`&.${paperClasses.root}`]: {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        color: "white"
      }
    },
    rewardsPage: {}
  };

  let currentTierDescription = get(currentTier, "description") || "\n";

  if (includes(currentTierDescription, "$$")){
    map(currentTierDescription.match(/\$\$x[\d\.]+/g), (match) => {
      const amount = match.match(/[\d\.]+/);
      currentTierDescription = currentTierDescription.replace(match, round(amount * totalAmount));
    });
  }
  
  return (
    <Paper sx={variantStylesMap[variant]}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography m={"12px"} variant="h2">
          {T("You have reached:")}
        </Typography>
        <Chip
          label={T(currentTier.name)}
          style={{
            backgroundColor: currentTier.badgeBackgroundColor,
            color: currentTier.badgeTextColor,
          }}
        />
      </Box>
      {currentTierDescription && <Typography variant="body2" style={{ paddingBottom: "45px" }}>
        <RichText>{T(currentTierDescription)}</RichText>
      </Typography>}
      <Stack direction="row" m={2} spacing={1}>
        {visibleTiers.map((tier, i) => {
          const { groupId, conditionalAmount } = tier;

          return (
            <Box sx={{ width: `${100 / visibleTiers.length}%`}}>
              <TierItem
                amount={amounts[i]}
                tier={{ ...find(groups, { id: groupId }), ...tier }}
                currencySymbol={currencySymbol}
                scaleLabel={conditionalAmount}
              />
            </Box>
          );
        })}
      </Stack>
    </Paper>
  );
};

export default TiersList;
