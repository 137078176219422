import React from "react";
import * as styles from "./index.module.scss";

const Chip = ({ label, style }) => {
  return (
    <div className={styles.Chip} style={style}>
      <div className={styles.ChipLabel}>{label}</div>
    </div>
  );
};

export default Chip;